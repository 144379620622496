/**
 * _browser.js
 * Display a message to non supported browser.
 *
 * If needed you can update the markup of the message
 * and/or the body of browserIsCompatible() function to adjust the test based on which browsers you want to support.
 */

$(function() {
  'use strict';

  var html = '<p class="c-browser">\
                <strong>Votre navigateur est désuet !</strong>\
                <br />\
                Mettez à jour votre navigateur pour afficher correctement ce site Web.\
                <br />\
                <a href="http://outdatedbrowser.com/fr" class="c-button">\
                  <span class="button__text">Mettre à jour maintenant</span>\
                </a>\
              </p>';

  function browserIsCompatible(){
    // By default we treat all browsers has compatible
    var isCompatible = true;

    // /*
    //  * No IE versions supports @supports{} and if we rely on caniuse stats this feature is pretty well supported :
    //  * https://caniuse.com/#search=supports
    //  * So this must cover our comptaibility expectations (usually last 2 versions)
    //  */
    // var cssSupports = 'CSS' in window && 'supports' in window.CSS || 'supportsCSS' in window;
    // // Target IE11 only : https://stackoverflow.com/questions/21825157/internet-explorer-11-detection
    // var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

    // // If doesn't support @supports{} and is not IE11
    // if(!cssSupports && !isIE11){
    //   isCompatible = false;
    // }
    
    var version = detectIE();
    if (version && version < 12) {
      isCompatible = false;
    }

    return isCompatible;
  }

  // When DOM is ready, if browser is not supported then we display the disclaimer message.
  $(document).ready(function(){
    if(!browserIsCompatible()){
      $('body').prepend(html);
    }
  });

  /**
   * https://codepen.io/gapcode/pen/vEJNZN
   * detect IE
   * returns version of IE or false, if browser is not Internet Explorer
   */
  function detectIE() {
    var ua = window.navigator.userAgent;

    // Test values; Uncomment to check result …

    // IE 10
    // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';
    
    // IE 11
    // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';
    
    // Edge 12 (Spartan)
    // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';
    
    // Edge 13
    // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      var rv = ua.indexOf('rv:');
      return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
  }

});
