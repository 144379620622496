$(function() {
  $('.js-scroll-to').click(function (e) {
    e.preventDefault();

    var page = $(this).data('target'),
        speed = 750,
        menuHeight,
        windowWidth;

    function getWindowWith() {
      windowWidth = $(window).width();
    }

    getWindowWith();

    $(window).resize(function () {
      getWindowWith();
    });

    if (windowWidth >= 720) {
      menuHeight = 75;
    }
    else {
      menuHeight = 50;
    }

    if ($('#wpadminbar').length > 0) {
      menuHeight += 50;
    }

    var $page = $(page);
    var scrollTop = $page.offset().top;

    if (!$page.hasClass('u-anchor')) {
      scrollTop -= menuHeight;
    }

    $('html, body').animate({
      scrollTop: scrollTop
    }, speed);

    return false;
  });
});
